import React, { ChangeEvent, useEffect, useState } from 'react'
import { SectionIds, STEP, ToastError } from '@qtc-repo/common/constants'
import TextInput from '@qtc-repo/ui/components/TextInput/TextInput'
import InputHelper from '@qtc-repo/ui/components/Form/InputHelper'
import { useSystemValues } from '@/contexts/ValueContext'
import { toast } from 'sonner'

type Props = {
  offscreen?: boolean
}

const Phone = ({ offscreen }: Props) => {
  const { userData: data, firstEvents, handleFormChange, setUseAutoComplete, setStepValid } = useSystemValues()

  const [inputFocused, setInputFocused] = useState(false)

  const { insurance, signatureData, ...details } = data
  const isPhoneValid = details.phone?.length === 11 && details.phone.startsWith('07')

  useEffect(() => {
    if (isPhoneValid) {
      toast.dismiss(ToastError.TELEPHONE)
    }
    setStepValid(prev => ({
      ...prev,
      [STEP.PHONE]: isPhoneValid,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.phone])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target
    value = value.slice(0, 11).replace(/\D/g, '')
    handleFormChange(name, value)
    if (offscreen) {
      setUseAutoComplete(true)
    }
  }
  return (
    <div
      className={`form-group sm:col-span-2 ${
        firstEvents.phone || inputFocused ? '' : isPhoneValid ? 'success' : 'error'
      }`}
      id={SectionIds.PHONE}
    >
      <TextInput
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            className={`h-5 w-5 text-gray-500 dark:text-gray-400 ${
              !(firstEvents.phone || isPhoneValid || inputFocused) && 'error'
            }`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H5.153C5.38971 2.00011 5.6187 2.08418 5.79924 2.23726C5.97979 2.39034 6.10018 2.6025 6.139 2.836L6.879 7.271C6.91436 7.48222 6.88097 7.69921 6.78376 7.89003C6.68655 8.08085 6.53065 8.23543 6.339 8.331L4.791 9.104C5.34611 10.4797 6.17283 11.7293 7.22178 12.7782C8.27072 13.8272 9.52035 14.6539 10.896 15.209L11.67 13.661C11.7655 13.4695 11.9199 13.3138 12.1106 13.2166C12.3012 13.1194 12.5179 13.0859 12.729 13.121L17.164 13.861C17.3975 13.8998 17.6097 14.0202 17.7627 14.2008C17.9158 14.3813 17.9999 14.6103 18 14.847V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H15C7.82 18 2 12.18 2 5V3Z" />
          </svg>
        }
        label={'Mobile telephone number'}
        type="tel"
        name="phone"
        id="phone"
        placeholder="07123 456789"
        value={data.phone}
        onChange={e => handleInputChange(e)}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
      />
      {firstEvents.phone || isPhoneValid || inputFocused ? (
        <InputHelper text="We need this so we can keep you updated on your claim by sms" />
      ) : (
        <InputHelper text=" Please enter a valid mobile number." error />
      )}
    </div>
  )
}

export default Phone
