import { ChangeEvent, useEffect, useState } from 'react'
import { SectionIds, STEP, ToastError } from '@qtc-repo/common/constants'
import TextInput from '@qtc-repo/ui/components/TextInput/TextInput'
import InputHelper from '@qtc-repo/ui/components/Form/InputHelper'
import { useSystemValues } from '@/contexts/ValueContext'
import { validateEmail } from '@qtc-repo/common/utils'
import { toast } from 'sonner'
import { useEmailValidator } from '@qtc-repo/common/hooks/useEmailValidator'

const Email = ({ offscreen }: { offscreen?: boolean }) => {
  const {
    userData: data,
    firstEvents,
    handleFormChange,
    setUseAutoComplete,
    emailValidation,
    setStepValid,
  } = useSystemValues()

  const { ValidateEmail } = useEmailValidator()
  const [inputFocused, setInputFocused] = useState({
    email: false,
    phone: false,
  })

  const { insurance, signatureData, ...details } = data
  const valid = !!details.email && validateEmail(details.email) && !!emailValidation.isValid

  useEffect(() => {
    if (valid) {
      toast.dismiss(ToastError.EMAIL)
    }
    setStepValid(prev => ({ ...prev, [STEP.EMAIL]: valid }))
    //eslint-disable-next-line
  }, [valid])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target
    handleFormChange(name, value.trim())
    if (offscreen) {
      setUseAutoComplete(true)
    }
  }
  return (
    <div
      className={`form-group sm:col-span-2 ${
        firstEvents.email || inputFocused.email || emailValidation.loading ? '' : valid ? 'success' : 'error'
      }`}
      id={SectionIds.EMAIL}
    >
      <TextInput
        icon={
          <svg
            aria-hidden="true"
            className={`h-5 w-5 text-gray-500 dark:text-gray-400 ${
              !emailValidation.loading &&
              !(firstEvents.email || emailValidation.isValid || inputFocused.email) &&
              'error'
            }`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
          </svg>
        }
        label={'Email address'}
        type="email"
        name="email"
        id="email"
        placeholder="e.g. john.doe@example.com"
        value={data.email}
        onChange={async e => {
          handleInputChange(e)
          if (offscreen) {
            await ValidateEmail(e.target.value.trim())
          }
        }}
        onFocus={() => setInputFocused({ ...inputFocused, email: true })}
        onBlur={async () => {
          await ValidateEmail(data.email)
          setInputFocused({ ...inputFocused, email: false })
        }}
        loading={emailValidation.loading}
      />

      {firstEvents.email || emailValidation.isValid || inputFocused.email ? (
        <InputHelper>We need this so we can keep you updated on your claim by email</InputHelper>
      ) : (
        <InputHelper text="Please enter a valid email address." error />
      )}
    </div>
  )
}

export default Email
