import Contact from '@/components/steps/Contact'
import Address from '@/components/steps/Address'

const TempDetails = () => {
  return (
    <div className="absolute max-h-0 overflow-hidden">
      <Contact offscreen />
      <Address offscreen />
    </div>
  )
}

export default TempDetails
