import React, { useEffect, useState } from 'react'
import { SectionIds, STEP, ToastError } from '@qtc-repo/common/constants'
import TextInput from '@qtc-repo/ui/components/TextInput/TextInput'
import InputHelper from '@qtc-repo/ui/components/Form/InputHelper'
import { useSystemValues } from '@/contexts/ValueContext'
import { toast } from 'sonner'

const Name = () => {
  const { userData, firstEvents, setStepValid, handleFormChange } = useSystemValues()
  const [inputFocused, setInputFocused] = useState({
    firstName: false,
    lastName: false,
  })
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target
    switch (name) {
      case 'firstName':
      case 'lastName':
        value = value
          .replace(/\s+(\S)/g, '-$1') //replace spaces with '-'
          .replace(/-+/g, '-') //enforce the occurence of only one consecutive hyphen
          .replace(/[^a-z\-\s]/gi, '')
          .replace(/^-+/, '')
        value = value.charAt(0).toUpperCase() + value.slice(1)
        break
    }
    handleFormChange(name, value)
  }
  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let { name, value } = e.target
    setInputFocused({ ...inputFocused, [name]: false })
    if (!value) return
    value = value.replace(/-$/g, '').replace(/\s+$/g, '')
    handleFormChange(name, value)
  }
  useEffect(() => {
    if (userData.firstName.length > 1) {
      toast.dismiss(ToastError.FIRST_NAME)
    }
    if (userData.lastName.length > 1) {
      toast.dismiss(ToastError.LAST_NAME)
    }
    setStepValid(s => ({
      ...s,
      [STEP.NAME]: userData.firstName.length > 1 && userData.lastName.length > 1,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])
  return (
    <div className={'grid w-full gap-5 sm:grid-cols-2'}>
      <TextInput
        name="firstName"
        id="first-name"
        sectionid={SectionIds.NAME}
        placeholder="e.g. Joe"
        onFocus={() => setInputFocused(prev => ({ ...prev, firstName: true }))}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        value={userData.firstName}
        label={'First name(s)'}
        error={!firstEvents.firstName && !(userData.firstName.length > 1) && !inputFocused.firstName}
        success={userData.firstName.length > 1 && !inputFocused.firstName}
        autoComplete="given-name"
        helper={
          firstEvents.firstName ? (
            ''
          ) : !userData.firstName && !inputFocused.firstName ? (
            <InputHelper text="Please let us know your first name" error />
          ) : (
            userData.firstName.length === 1 &&
            !inputFocused.firstName && <InputHelper text="Please enter a valid name" error />
          )
        }
      />
      <TextInput
        label={'Last name'}
        name="lastName"
        placeholder="e.g. Bloggs"
        autoComplete="family-name"
        value={userData.lastName}
        error={!firstEvents.lastName && !(userData.lastName.length > 1) && !inputFocused.lastName}
        success={userData.lastName.length > 1 && !inputFocused.lastName}
        onFocus={() => setInputFocused(prev => ({ ...prev, lastName: true }))}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        id="last-name"
        helper={
          firstEvents.lastName ? (
            ''
          ) : !userData.lastName && !inputFocused.lastName ? (
            <InputHelper text="Please let us know your last name" error />
          ) : (
            userData.lastName.length === 1 &&
            !inputFocused.lastName && <InputHelper text="Please enter a valid name" error />
          )
        }
      />
    </div>
  )
}

export default Name
