import { useEffect } from 'react'
import { useSystemValues } from '@/contexts/ValueContext'
import { Brands, PDFTYPE, SectionIds, STEP, ToastError } from '@qtc-repo/common/constants'
import { toast } from 'sonner'
import BasicInfo from '@/components/BasicInfo/BasicInfo'
import Address from './Address'
import { Email, Phone } from '@/components/Contact'
import LinkText from '@qtc-repo/ui/components/LinkText/LinkText'
import dynamic from 'next/dynamic'
import { getElementTop, scrollToTop } from '@qtc-repo/common/utils'

const Checkbox = dynamic(() => import('@qtc-repo/ui/components/Checkbox/Checkbox'), {})

const Details = ({ offscreen }: { offscreen?: boolean }) => {
  const {
    firstEvents,
    titleRef,
    step,
    detailsChecked: checked,
    setDetailsChecked: setChecked,
    openPdf,
    setStepValid,
    brand,
    stepValid,
  } = useSystemValues()

  useEffect(() => {
    if (!titleRef.current) return
    if (step === STEP.CONTACT) {
      setTimeout(() => titleRef.current?.classList.add('flash'), 0)
    } else {
      titleRef.current?.classList.remove('flash')
    }
  }, [titleRef, step])

  const valid = stepValid[STEP.PHONE] && stepValid[STEP.EMAIL]

  useEffect(() => {
    setStepValid(prev => ({ ...prev, [STEP.CONTACT]: valid }))
    //eslint-disable-next-line
  }, [valid])

  return (
    <>
      <div className="mb-5 grid gap-5 sm:grid-cols-2">
        {brand === Brands.QTC && <BasicInfo />}
        <Phone offscreen={offscreen} />
        <Email offscreen={offscreen} />

        {brand === Brands.ACG && (
          <div className="sm:col-span-2" id={SectionIds.ADDRESS}>
            <Address />
          </div>
        )}

        {!offscreen && (
          <div id={SectionIds.CONTACT_CONFIRM} className="mt-5 sm:col-span-2">
            <Checkbox
              id="radio-confirm-1"
              error={!firstEvents.details && !checked}
              checked={checked}
              onChange={checked => {
                setChecked(checked)
                localStorage.setItem('details_checked', JSON.stringify(checked))
                if (checked) {
                  toast.dismiss(ToastError.DETAILS_CONFIRMATION)
                  scrollToTop(getElementTop('btnNext-' + step))
                }
              }}
              label={
                <>
                  By clicking continue, you confirm that you have read and agree to the{' '}
                  <LinkText text={'Privacy Policy'} onClick={() => openPdf(PDFTYPE.PRIVACY_POLICY)} />
                </>
              }
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Details
