import Name from '@/components/BasicInfo/Name'
import DOB from '@/components/BasicInfo/DOB'

const BasicInfo = () => {
  return (
    <div className={'flex w-full flex-col gap-5 sm:col-span-2'}>
      <Name />
      <DOB />
    </div>
  )
}

export default BasicInfo
