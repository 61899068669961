import { SectionIds, STEP, ToastError } from '@qtc-repo/common/constants'
import { useSystemValues } from '@/contexts/ValueContext'
import { toast } from 'sonner'
import pick from 'lodash/pick'
import dynamic from 'next/dynamic'

const DateField = dynamic(() => import('@qtc-repo/ui/components/DateField/DateField'), {})

const DOB = () => {
  const { userData, firstEvents, setStepValid, stepValid, handleFormChange } = useSystemValues()

  return (
    <div className=" form-group w-full sm:col-span-2" id={SectionIds?.DOB}>
      <DateField
        firstEvents={pick(firstEvents, ['day', 'month', 'year'])}
        onChange={handleFormChange}
        maxYear={2005}
        minYear={1923}
        handleFieldError={error => {
          if (!error) {
            toast.dismiss(ToastError.DOB)
          }
          setStepValid(s => ({
            ...s,
            [STEP?.DOB]: !error,
          }))
        }}
        value={pick(userData, ['day', 'month', 'year'])}
      />
    </div>
  )
}

export default DOB
